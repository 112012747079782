import _ from 'lodash';

const ConfigService = {

    get: (varName, shouldThrowException = false) => {
        if (!(window.COVR_ENV instanceof Object)) {
            throw new ReferenceError('No window.COVR_ENV environment found!');
        }

        const value = _.get(window.COVR_ENV, varName, undefined);
        if ((value === undefined) && shouldThrowException) {
            throw new ReferenceError(`No such COVR_ENV config variable: ${varName}`);
        }

        return value;
    }

}

export { ConfigService };